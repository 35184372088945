import { Text, Icon, VStack } from "@chakra-ui/react"

type Props = {
    icon : any
    title : string
    explain1 : string
    explain2 : string
}

// スマホ用のコンポーネント
export const ConceptItem = ({ icon, title, explain1, explain2 }: Props) => {

    return (
        <>
            <VStack spacing={4} alignItems="center" mb={20}>
                <Icon as={icon} fontSize="6xl" color={"gray.400"} />
                <Text fontSize="xl" fontWeight="bold">
                    {title}
                </Text>
                <Text fontWeight="bold" whiteSpace={"pre-wrap"}>
                    {explain1}<br />{explain2}
                </Text>
            </VStack>
        </>
    )
};
