import { memo, useEffect, useState } from "react";

import { ServiceCardDesktop } from "./ServiceCardDesktop"
import { ServiceCardMobile } from "./ServiceCardMobile"

type Props = {
    imagePath : string
    title : string
    explain : string
    index: number
}

export const ServiceCard = memo((props: Props) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // ウィンドウサイズが変更されたときに実行されるリスナー
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        // コンポーネントがマウントされたときにリスナーを追加
        window.addEventListener("resize", handleResize);

        // コンポーネントがアンマウントされたときにリスナーを削除
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // ウィンドウ幅に応じてコンポーネントを切り替え
    if (isMobile) {
        return <ServiceCardMobile {...props} />;
    } else {
        return <ServiceCardDesktop {...props} />;
    }
});
