import { memo } from "react";
import { Table,Tbody,Tr,Td,TableContainer} from "@chakra-ui/react"

const tableData = [
    {key : '会社名', value : 'ビューチャー株式会社 （英名：Viewture Inc.）'},
    {key : '設立', value : '2023年10月'},
    {key : '住所', value : '〒214-0022 神奈川県川崎市多摩区堰3-4-27 パールハイツ303'},
    {key : '電話番号', value : '03-3589-6909'},
    {key : '資本金', value : '100万円(2023年10月現在)'},
    {key : '代表取締役', value : '佐藤 恵太'},
    // {key : '取締役', value : '柿崎 希'},
    // {key : '従業員数', value : '0'},
]

export const MyTable = memo(() => {
    return (
        <>
            <TableContainer>
                <Table variant='simple' borderStyle="dashed">
                    <Tbody>
                    {tableData.map( (row) => (
                        <Tr>
                            <Td valign='top' fontWeight="bold">{row.key}</Td>
                            <Td whiteSpace='pre-line' fontWeight="bold" opacity={0.9}>{row.value}</Td>
                        </Tr>
                    ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    )
})