import { memo, ReactNode } from "react";
import { Box, Text } from "@chakra-ui/react"
import { Link as Scroll } from 'react-scroll'
import { motion } from 'framer-motion';

type Props = {
    to : string;
    children : ReactNode;
    onClick : () => void;
    offset? : number;
}

export const MenuLink = memo((props : Props) => {
    const {to, children, onClick, offset=0} = props;

    return (
        <>
            <Box  _hover={{ cursor: "pointer" }}>
                    <Scroll
                        to={to} 
                        smooth={true}
                        onClick={onClick}
                        offset={offset}
                    >
                        <motion.div 
                            whileHover={{ 
                                scale : 1.2
                            }}
                        >

                        <Text 
                            color="white"
                            variant="underline"
                            fontSize='xl'
                            fontFamily="arial narrow"
                            fontWeight='bold'
                            transform="scale(1, 1.05)"
                            letterSpacing={2}
                            _hover={{
                                cursor: "pointer",
                                textDecoration: "none",
                            }}
                        >
                            {children}
                        </Text>

                        </motion.div>
                    </Scroll>

            </Box>
        </>
    )
})