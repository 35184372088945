import { memo } from "react";
import { VStack } from "@chakra-ui/react"

import { Title } from "../atoms/header/Title";
import { MyTable } from "../atoms/table/MyTable";
import { FadeInFromBottom } from "../atoms/motion/FadeInFromBottom";

export const Company = memo(() => {
    return (
        <>
            <VStack>
                <Title>
                    COMPANY INFO
                </Title>

                <FadeInFromBottom>
                    <MyTable />
                </FadeInFromBottom>
                
            </VStack>
        </>
    )
})