import { memo } from "react";
import { Box, Flex, VStack } from "@chakra-ui/react"
import { FaDatabase, FaRobot, FaCogs } from 'react-icons/fa';


import { Title } from "../atoms/header/Title";
import { ConceptItem } from "../molecules/ConceptItem"
import { FadeInFromBottom } from "../atoms/motion/FadeInFromBottom"

const items = [
    { icon : FaDatabase, title : "データを中心に物事を考える", explain1 : "私たちはデータを活用し、洞察を得てビジネス上の意思決定をサポートします。", explain2 : "データ駆動型のアプローチで、あらゆる課題に対処します。"},
    { icon : FaRobot, title : "小さなことでも自動化する", explain1 : "私たちは効率と自動化に注力し、小さなタスクから大きなプロセスまでを自動化します", explain2 : "時間とリソースの節約を実現します。"},
    { icon : FaCogs, title : "新しい技術を日々取り入れる", explain1 : "私たちは常に最新のテクノロジーとサービスに対する情報を追求し、新しいサービスを積極的に導入します。", explain2 : "顧客に最高の価値を提供します。"},    
]

export const Concept = memo(() => {
    return (
        <>
            <VStack width='100%'>
                <Title>CONCEPT</Title>

                <Flex justifyContent="center" alignItems="center" p={8}>
                    <Box maxW="100%" minW="0" textAlign="center">
                        <Flex direction="column" justifyContent="center" alignItems="center" mb={8}>

                        {items.map( (item) => (
                            <FadeInFromBottom>
                                <ConceptItem 
                                    icon={item.icon} 
                                    title={item.title} 
                                    explain1={item.explain1}
                                    explain2={item.explain2}
                                />
                            </FadeInFromBottom>
                        ))}

                        

                        </Flex>
                    </Box>
                </Flex>

            </VStack>
        </>
    )
})