import { memo } from "react";
import { Box, Text } from "@chakra-ui/react";

export const Footer = memo(() => {
  return (
    <Box
      bg="blue.500" // 青色の背景色
      color="white" // 白色の文字色
      py={10} // 上下の余白
      textAlign="center" // テキストを中央に配置
      height="150px"
      fontFamily={"Arial Black"}
    >
      <Text fontSize="sm">
        Copyright © Viewure Inc. All rights reserved.
      </Text>
    </Box>
  );
});
