import { memo, ReactNode } from "react";
import { Box, Center, Divider, Text, VStack } from "@chakra-ui/react"

import { FadeInFromLeft } from "../motion/FadeInFromLeft"

type Props = {
    children : ReactNode
}

export const Title = memo((props: Props) => {
    const { children } = props;
    return (
        <>
        <FadeInFromLeft>
        <Box 
            width='100%'
            pt={150}
            pb={30}
        >
            <Center>
            <VStack>
                <Text 
                    variant="underline"
                    fontSize='3xl'
                    fontFamily="arial narrow"
                    fontWeight='bold'
                    transform="scale(1, 1.05)"
                    letterSpacing={3}
                >
                    {children}
                </Text>
                <Divider orientation='horizontal' borderColor="black" width="60px" borderWidth="2px" />
            </VStack>
            </Center>
        </Box>
        </FadeInFromLeft>
        </>
    )
})