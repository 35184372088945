import { memo } from "react";
import { VStack } from "@chakra-ui/react"

import { Title } from "../atoms/header/Title";
import { ServiceCard } from "../molecules/ServiceCard";
import { FadeInFromBottom } from "../atoms/motion/FadeInFromBottom"

const items = [
    { imagePath : "consult.png", title : "コンサルティング", explain : "IT戦略の策定から実行に至るまでの支援をします。ビジネスの変革を成功に導くために、テクノロジーアドバイス、プロジェクトマネジメント、戦略的計画を通じてクライアントに付加価値を提供します。" },
    { imagePath : "design.png", title : "デザイン", explain : "ユーザーエクスペリエンスを一貫して向上させます。魅力的な視覚デザインと使いやすいインターフェースを組み合わせ、ユーザーが魅了され、簡単に操作できるアプリケーションとウェブサイトを提供します。"},
    { imagePath : "coding.png", title : "コーディング", explain : "最新の技術とベストプラクティスを駆使して、カスタムソリューションを提供します。効率的なコードとスケーラビリティを重視し、品質と信頼性を一貫して追求します。"},
    { imagePath : "infra.png", title : "インフラ構築", explain : "クラウド、オンプレミス、またはハイブリッドインフラストラクチャを設計・実装します。セキュリティと可用性を最優先に考え、ビジネスの成長に対応する柔軟でスケーラブルなインフラを提供します。安心してビジネスを拡大できます。" },
    { imagePath : "IoT.png", title : "IoT", explain : "センサーデータの収集からリアルタイム分析、デバイス管理まで、完全なエンドツーエンドのソリューションを提供します。クライアントのビジョンを実現し、効率性、予測性、競争力を向上させるためにIoTテクノロジーを活用します。" },
    { imagePath : "data.png", title : "データ分析", explain : "データ駆動の意思決定を実現します。高度な分析ツールと専門知識を駆使し、クライアントのデータから洞察を引き出し、ビジネス戦略の最適化、市場傾向の洞察、競争力の向上を支援します。データを有用な情報に変えます。" },
]

export const Service = memo(() => {
    return (
        <>
            <VStack width='80%'>
                <Title>
                    SERVICE
                </Title>

                {items.map( (item, index) => (
                    <FadeInFromBottom>
                        <ServiceCard 
                            imagePath={item.imagePath}
                            title={item.title}
                            explain={item.explain}
                            index={index}
                        />
                    </FadeInFromBottom>
                ))}
                
            </VStack>

        </>
    )
})