import { memo } from "react";
import { VStack ,Text, Center, Divider } from "@chakra-ui/react"

import { Title } from "../atoms/header/Title";
import { RecruitItem } from "../molecules/RecruitItem"
import { FadeInFromBottom } from "../atoms/motion/FadeInFromBottom";

const items = [
    { explain1 : "写真や動画を世界中と共有できる", explain2 : "あらゆるものがいつでも買える", explain3 : "転職活動だってできる" },
    { explain1 : "過去の人々が今日の暮らしを想像できなかったように", explain2 : "今日の人々が想像できないワクワクする便利な未来", explain3 : "私たちと作りませんか" },
    { explain1 : "スタートアップ特有のスピード感", explain2 : "技術力と情熱溢れるメンバーたち", explain3 : "そんな環境を求めてる人に私たちは出会いたい" },
    { explain1 : "「仕事内容は？」", explain2 : "「メンバーの雰囲気は？」", explain3 : "カジュアルな質問から詳細な仕事内容まで何でもお答えします" },
    { explain1 : "【sato-k@viewture.jp】", explain2 : "", explain3 : "" },
]

export const Recruit = memo(() => {
    return (
        <>
            <VStack width='80%'>
                <Title>
                    RECRUIT
                </Title>

                <FadeInFromBottom>
                <Center>
                    <Text
                        fontSize={{base : "xl", md : "4xl"}} 
                        fontFamily="impact"
                        letterSpacing={3}
                    >
                        VIEWTURE = VIEW + FUTURE
                    </Text>
                </Center>

                <Center>
                    <Text  
                        fontSize={{base : "xl", md : "2xl"}}
                        fontWeight="bold" 
                        fontFamily="arial narrow" 
                    >
                        ITの力で人々に未来を見せる
                    </Text>
                </Center>

                <Center>
                <Divider orientation='horizontal' borderColor="black" width="120px" borderWidth="2px" mb={10} />
                </Center>
                
                </FadeInFromBottom>

                {items.map( (item) => (
                    <FadeInFromBottom>
                        <RecruitItem  
                            explain1={item.explain1}
                            explain2={item.explain2}
                            explain3={item.explain3}
                        />
                    </FadeInFromBottom>
                ))}
                
            </VStack>
        </>
    )
})