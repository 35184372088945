import { memo } from "react";
import { Flex } from "@chakra-ui/react"

import { Service } from "../organisms/Service";
import { Concept } from "../organisms/Concept";
import { Company } from "../organisms/Company";
import { Recruit } from "../organisms/Recruit";
import { TopImage } from "../organisms/TopImage";
import { Footer } from "../atoms/footer/Footer";
import { FadeInFromBottom } from "../atoms/motion/FadeInFromBottom"

export const Home = memo(() => {
    return (
        <>
            <Flex width="100%" direction="column" mt={30} position="sticky" top={100} id="home">

                <section id="top">
                    <FadeInFromBottom>
                        <Flex justify="center" mt={-130} mb={30}>
                            <TopImage />
                        </Flex>
                    </FadeInFromBottom>
                </section>

                <section id="service">
                    <Flex justify="center">
                        <Service />
                    </Flex>
                </section>

                <section id="concept">
                    <Flex justify="center">
                        <Concept />
                    </Flex>
                </section>

                <section id="company">
                <Flex justify="center" mb={10}>
                    <Company />
                </Flex>
                </section>

                <section id="recruit">
                <Flex justify="center">
                    <Recruit />
                </Flex>
                </section>

                <Footer />

            </Flex>

        </>
    )
})