import { memo } from "react";
import { Text } from "@chakra-ui/react"

type Props = {
    explain1 : string
    explain2 : string
    explain3 : string
}

const convertNewlinesToBr = (text) => {
    return text.replace(/\n/g, '<br />');
}

export const RecruitItem = memo((props : Props) => {
    const {explain1, explain2, explain3} = props;

    return (
        <>
                <Text
                    fontSize={{base : "md", md : "md"}}
                    fontWeight="bold" 
                    textAlign="center" 
                    fontFamily="serif" 
                    whiteSpace='pre-line'
                    lineHeight={{base: "150%", md: "0"}}
                >
                    {convertNewlinesToBr(explain1)}
                </Text>
                <br />
                <Text
                    fontSize={{base : "md", md : "md"}}
                    fontWeight="bold" 
                    textAlign="center" 
                    fontFamily="serif" 
                    whiteSpace='pre-line'
                    lineHeight={{base: "150%", md: "0"}}
                >
                    {explain2}
                </Text>
                <br />
                <Text
                    fontSize={{base : "md", md : "md"}}
                    fontWeight="bold" 
                    textAlign="center" 
                    fontFamily="serif" 
                    whiteSpace='pre-line'
                    lineHeight={{base: "150%", md: "0"}}
                >
                    {explain3}
                </Text>
                <br /><br />
        </>
    )
})