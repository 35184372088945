import { Route, Routes } from "react-router-dom";
import { memo } from "react";

import { Home } from "../components/pages/Home";
import { HeaderLayout } from "../components/templates/HeaderLayout";

export const Router = memo(() => {
    return (
        <Routes>
            <Route path="/" element={
            <HeaderLayout>
                <Home />
            </HeaderLayout>}/>
        </Routes>
    )
});