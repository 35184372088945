import { memo, ReactNode, useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";

type Props = {
    children: ReactNode;
}

export const FadeInFromLeft = memo((props: Props) => {
    const { children } = props;

    const containerRef = useRef(null);
    const controls = useAnimation();

    useEffect(() => {
        const container = containerRef.current;
        if (!container) return;

        const handleIntersection = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // 画面内に要素が入ったらアニメーションを発火
                    controls.start("visible");
                    observer.unobserve(entry.target); // 1回だけ実行するため監視を停止
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            rootMargin: "-30%",
        });

        const target = containerRef.current;
        if (target) {
            // コンポーネントがマウントされたら要素を監視
            observer.observe(target);
        }

        return () => {
            // コンポーネントがアンマウントされたら監視を停止
            if (target) {
                observer.unobserve(target);
            }
        };
    }, [controls]);

    const fadeInVariants = {
        hidden: {
            opacity: 0,
            x: "-10vw", // 左から10vw移動
        },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                duration: 0.8, // アニメーションの時間（秒）
            },
        },
    };

    return (
        <div ref={containerRef}>
            <motion.div
                variants={fadeInVariants}
                initial="hidden"
                animate={controls}
            >
                {children}
            </motion.div>
        </div>
    );
});
