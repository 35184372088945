import { Box, Flex, Image, Text } from "@chakra-ui/react"

type Props = {
    imagePath : string
    title : string
    explain : string
    index: number
}

// スマホ用のコンポーネント
export const ServiceCardMobile = ({ imagePath, title, explain, index }: Props) => {

    return (
        <>
            <Flex 
                alignItems="center"
                flexDirection="column"
                width="100%"
                mb={5}
            >
                <Image
                    src={imagePath} 
                    alt={title} 
                    width="60%"
                    filter="grayscale(50%);"
                />
                <Box>
                    <Text fontWeight="bold" fontSize="3xl" textAlign="center">{title}</Text>
                    <Text mt={4} fontWeight="bold">{explain}</Text>
                </Box>
            </Flex>
        </>
    )
};
