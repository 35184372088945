import { Box, Flex, Image, Text } from "@chakra-ui/react"

type Props = {
    imagePath : string
    title : string
    explain : string
    index: number
}

// PC用のコンポーネント
export const ServiceCardDesktop = ({ imagePath, title, explain, index }: Props) => {
    const isEven = index % 2 === 0;

    return (
        <>
            <Flex 
                alignItems="center"
                justify="center"
                flexDirection="row"
                width="100%"
                my={0}
                minH={300}
            >
                {isEven ? (
                    <>
                        <Image
                            src={imagePath} 
                            alt={title} 
                            width="15%"
                            filter="grayscale(50%);"
                            mr={20}
                        />
                        <Box width="30%">
                            <Text fontWeight="bold" fontSize="3xl" textAlign="left" fontFamily={"sans-serif"}>{title}</Text>
                            <Text mt={5} fontWeight="bold">{explain}</Text>
                        </Box>
                    </>
                ) : (
                    <>
                        <Box width="30%">
                            <Text fontWeight="bold" fontSize="3xl" textAlign="left" fontFamily={"sans-serif"}>{title}</Text>
                            <Text mt={5} fontWeight="bold">{explain}</Text>
                        </Box>
                        <Image 
                            src={imagePath} 
                            alt={title} 
                            width="15%"
                            filter="grayscale(50%);"
                            ml={20}
                        />
                    </>
                )}
            </Flex>
        </>
    )
};