import { memo } from "react";
import { Center, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Flex, IconButton, VStack } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

import { MenuLink } from "../atoms/menu/MenuLink"

type Props = {
    onClose: () => void;
    isOpen: boolean;
};

export const MenuDrawer = memo((props: Props) => {
    const { onClose, isOpen } = props;

    return (
        <Drawer placement="right" size="md" onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay>
                <DrawerContent bg="blackAlpha.800">

                    <Flex justify="flex-end" py={4} px={2}>
                        <IconButton
                            icon={<CloseIcon />}
                            aria-label="Close"
                            onClick={onClose}
                            size="sm"
                            color="white"
                            bg="transparent"
                        />
                    </Flex>

                    <DrawerBody pt={150} onClick={onClose} justifyContent="flex-start">
                        <Center>
                            <VStack spacing={4} align="center">
                                <MenuLink to="top" onClick={onClose} offset={-300} >TOP</MenuLink>
                                <MenuLink to="service" onClick={onClose} >SERVICE</MenuLink>
                                <MenuLink to="concept" onClick={onClose} >CONCEPT</MenuLink>
                                <MenuLink to="company" onClick={onClose} >COMPANY</MenuLink>
                                <MenuLink to="recruit" onClick={onClose} >RECRUIT</MenuLink>
                            </VStack>
                        </Center>
                    </DrawerBody>

                </DrawerContent>
            </DrawerOverlay>
        </Drawer>
    )
});
