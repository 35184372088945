import { memo, ReactNode } from "react";
import { Box, Text } from "@chakra-ui/react"
import { Link as Scroll } from 'react-scroll'
import { motion } from 'framer-motion';

type Props = {
    to : string;
    children : ReactNode;
    offset? : number;
}

export const HeaderLink = memo((props : Props) => {
    const {to, children, offset=0} = props;

    return (
        <>
            <Box pe={4} _hover={{ cursor: "pointer" }}>
                    <Scroll
                        to={to} 
                        fontFamily="arial narrow" 
                        fontWeight="bold"
                        smooth={true}
                        offset={offset}
                    >
                        <motion.div 
                            whileHover={{ 
                                scale : 1.2
                            }}
                        >
                            <Text
                                fontFamily="arial narrow"
                                fontWeight='bold'
                                transform="scale(1, 1.05)"
                                letterSpacing={1}
                            >
                        {children}
                        </Text>
                        </motion.div>
                    </Scroll>
            </Box>
        </>
    )
})