import { memo } from "react";
import { Image, Flex } from "@chakra-ui/react";

export const TopImage = memo(() => {
    return (
        <Flex
            justify="center"
            align="center"
            h="100vh" // 画面の高さいっぱいに広げる
        >
            <Image 
                src="/logo_viewture2.png" 
                maxW={{ base: "90%", md: "20%" }}
                border="0"
                minW="0"
                mb={3}
            />
        </Flex>
    );
});