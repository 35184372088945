import { memo } from "react";
import { HamburgerIcon } from "@chakra-ui/icons";

type Props = {
    onOpen: () => void;
}

export const MenuIconButton = memo((props : Props) => {
    const { onOpen } = props;
    return (
        <HamburgerIcon
            aria-label="メニューボタン" 
            display={{base: "block", md: "none"}} 
            boxSize={"2em"}
            onClick={onOpen}
        />
    )
})