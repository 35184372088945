import { memo, useEffect, useState } from "react";
import { Flex, Image, useDisclosure } from "@chakra-ui/react"
import { motion, useAnimation } from "framer-motion";
import { Link as Scroll } from 'react-scroll'

import { HeaderLink } from "../atoms/header/HeaderLink";
import { MenuIconButton } from "../atoms/button/MenuIconButton";
import { MenuDrawer } from "../molecules/MenuDrawer";

export const Header = memo(() => {
    const [scrollY, setScrollY] = useState(0);
    const controls = useAnimation(); // アニメーションコントロールの作成

    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const handleScroll = () => {
          setScrollY(window.scrollY);
        };
    
        window.addEventListener("scroll", handleScroll);
    
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);
    
      const shouldShowImage = scrollY >= window.innerHeight*0.45;

      useEffect(() => {
        // スクロール位置が420px以上の場合、フェードインアニメーションを開始
        if (shouldShowImage) {
          controls.start({ opacity: 1 });
        } else {
          controls.start({ opacity: 0 });
        }
      }, [shouldShowImage, controls]);

    return (
        <Flex 
            as="nav" 
            direction="row"
            padding={ { base: 2, md: 5 }}
            position="sticky"
            top={0}
            zIndex={999}
            width="100%"
            height="100px"
            backgroundColor={"white"}
        >

            <Flex
              align="center" 
              justify={{base : "center", md : "flex-start"}}
              ms={{base : "0%", md : "20%"}} 
              _hover={{ cursor: "pointer" }}
            >
              <motion.div 
                initial={{ opacity: 0 }} 
                animate={controls}
              >
                  <Scroll
                    to="top"
                    smooth={true}
                    offset={-130}
                    >
                    <Image 
                      src="/logo_viewture2.png" 
                      height={{ base : "60px", md : "60px"}}
                      my={3} 
                    />
                  </Scroll>
              </motion.div>
            </Flex>

            <Flex 
                align="center" 
                justify="flex-end"
                fontSize="md" 
                flexGrow={2}
                me={{base : 0, md : "20%"}}
                display={{ base: "none", md: "flex"}}
            >
                <HeaderLink to="service" >SERVICE</HeaderLink>
                <HeaderLink to="concept" >CONCEPT</HeaderLink>
                <HeaderLink to="company" >COMPANY</HeaderLink>
                <HeaderLink to="recruit" >RECRUIT</HeaderLink>
            </Flex>

            <Flex 
                align="center" 
                justify="flex-end"
                flexGrow={2}
                me="5%"
                display={{ base: "flex", md: "none"}}
            >
              <MenuIconButton
                onOpen={onOpen}
              />
            </Flex>

            <MenuDrawer 
              onClose={onClose} 
              isOpen={isOpen}
            />

        </Flex>
    )
})